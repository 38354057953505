import React, { useState } from "react"
import Layout from "../components/layout/Layout"
import { graphql } from "gatsby"
import ItemDisplayList from "../components/ItemDisplayList"
import Seo from "../components/SEO"
import SideBar from "../components/SideBar"
import Pagination from "../components/Pagination"

import { Stack } from "@mui/material"

const Guides = ({ data }) => {
  const {
    allMdx: { nodes: items },
  } = data

  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage] = useState(10)

  // Get current posts
  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = items.slice(indexOfFirstItem, indexOfLastItem)

  // Change page
  const paginate = pageNumber => {
    setCurrentPage(pageNumber)
  }

  return (
    <Layout>
      <Seo
        title="Free Vietnamese Lessons"
        desc="Free, easy online resources that help you learn Vietnamese quickly. Learn to speak Vietnamese today."
      />
      <div className="blog-container" style={{ margin: "2rem" }}>
        <Stack spacing={2}>
          <div>
            <h1 className="underline" style={{ fontSize: "var(--s3)" }}>
              Guides
            </h1>
          </div>

          <ItemDisplayList items={currentItems} />

          <div style={{ margin: "0 auto" }}>
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={items.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          </div>
        </Stack>

        <aside className="sidebar">
          <SideBar />
        </aside>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    allMdx(
      sort: { order: DESC, fields: frontmatter___date }
      filter: {
        frontmatter: { draft: { eq: false } }
        fileAbsolutePath: { regex: "/lesson/" }
      }
    ) {
      nodes {
        frontmatter {
          title
          categories
          date(formatString: "MMMM Do, YYYY")
          description
          menuTitle
        }
        id
        slug
      }
    }
  }
`

export default Guides
